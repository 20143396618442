<template>
<div>
    <v-data-table :loading="loadingTable" disable-pagination disable-sort hide-default-footer :headers="headers" :items="companies" sort-by="calories" class="elevation-1">
        <template v-slot:top>
            <v-toolbar flat>
                <v-toolbar-title>Produção Por Empresas</v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
                <v-menu :offset-y="true">
                    <template v-slot:activator="{ on: menu, attrs }">
                        <v-tooltip top>
                            <template v-slot:activator="{ on: tooltip }">
                                <v-btn color="primary" outlined icon large v-bind="attrs" v-on="{ ...tooltip, ...menu }">
                                    <v-icon>
                                        {{ icons.mdiTools }}
                                    </v-icon>
                                </v-btn>
                            </template>
                            <span>Ferramentas</span>
                        </v-tooltip>
                    </template>
                    <v-list>
                        <v-list-item @click="sendExportExcel()">
                            <v-list-item-title>
                                <v-icon>{{ icons.mdiFileExcelOutline  }}</v-icon> Exportar Excel
                            </v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
                <v-btn @click="dialogFilter=true" class="ml-2" rounded outlined large color="primary"><v-icon>{{icons.mdiFilter }}</v-icon>Filtros</v-btn>
                <v-text-field  :append-icon="icons.mdiMagnify" @click:append="confirmSearch()" @keyup.enter="confirmSearch()"  class="mt-6 ml-2" dense rounded outlined label="Pesquisar"  v-model="filter.search"></v-text-field>
                <v-spacer></v-spacer>

            </v-toolbar>
        </template>
        <template v-slot:foot="{ item }">
            
            <tr style="height:44px; background:#fafafa" >
                 <td colspan="2" style="border-top:solid 1px #e4e3e6;padding-left:16px;padding-right:16px">TOTAL DE EMPRESAS: <b>{{ qtdCompany() }}</b></td>
                 <td  colspan="2" style="border-top:solid 1px #e4e3e6;padding-left:16px;padding-right:16px">TOTAL ROOM-NIGHTS: <b>{{totalRoomNight()}} </b></td>
                 <td style="border-top:solid 1px #e4e3e6;padding-left:16px;padding-right:16px"></td>
                 <td style="border-top:solid 1px #e4e3e6;padding-left:16px;padding-right:16px"></td>
                 
            </tr>
            
            <tr style="height:44px; background:#fafafa">
                 <td colspan="2" style="border-top:solid 1px #e4e3e6;padding-left:16px;padding-right:16px">TOTAL DIÁRIAS (R$): <b> {{ formatMoney(diaryTotal()) }} </b></td>
                 <td colspan="2" style="border-top:solid 1px #e4e3e6;padding-left:16px;padding-right:16px">TOTAL DIÁRIA MEDIA (R$): <b> {{ formatMoney(diaryMedia()) }} </b></td>
                 <td  style="border-top:solid 1px #e4e3e6;padding-left:16px;padding-right:16px"></td>
                 <td  style="border-top:solid 1px #e4e3e6;padding-left:16px;padding-right:16px"></td>
            </tr>

        </template>       

        <template v-slot:item.sumDiary="{ item }">
            {{ formatMoney(item.sumDiary) }}
        </template>
        <template v-slot:item.mediaDiary="{ item }">
            {{ formatMoney(item.mediaDiary) }}
        </template>

        <template v-slot:item.actions="{ item }">
          <div style=" white-space: nowrap;">
             <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon  @click="showDetails(item)" :loading="item.loading_print" :disabled="item.loading_print" color="primary"  class="mr-2"  v-bind="attrs" v-on="on">
                  <v-icon  >
                    {{ icons.mdiMagnify  }}
                </v-icon>
                </v-btn>

            </template>
            <span>Detalhes</span>
            </v-tooltip>
          </div>
        </template>  

        <template v-slot:no-data>
            <NoDataTable></NoDataTable>
        </template>
        <template v-slot:loading>
            Carregando...
        </template>
    </v-data-table>

    <!-- DIALOG FILTRO -->
     <v-dialog v-model="dialogFilter" width="700">
      <v-card>
        <BarTitleDialog title="FILTROS" @close="dialogFilter = false" />
        <v-card-text>
          <v-row>
            <v-col cols="6">
              <v-text-field v-model="filter.checkout_date_start" outlined type="date" label="Data Checkout Inicial"></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field v-model="filter.checkout_date_end" outlined type="date" label="Data Checkout Final"></v-text-field>
            </v-col>
          </v-row>
         </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-row>
            <v-col class="text-left">
              <v-btn text color="error" x-large @click="clearFilter()">limpar filtros</v-btn>
            </v-col>
            <v-col class="text-right">
              <v-btn text color="secondary" x-large @click="dialogFilter = false">FECHAR</v-btn>
              <v-btn @click="confirmFilter()" color="primary" class="ml-2" x-large>aplicar</v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>


    <MsgDelete v-model="dialogDelete" @delete-yes="deleteItemConfirm()" />
</div>
</template>

<script>
import MsgDelete from '@/components/MsgDelete.vue'
import NoDataTable from '@/components/NoDataTable.vue'
import {
  mdiClose,
  mdiDeleteOutline,
  mdiPencilOutline,
  mdiPlus,
  mdiOpenInNew,
  mdiTools,
  mdiFileExcelOutline,
  mdiFilter,
  mdiMagnify,
} from '@mdi/js'
import { mapActions } from 'vuex'
import BarTitleDialog from '@/components/BarTitleDialog.vue'

export default {
  components: {
    MsgDelete,
    NoDataTable,
    BarTitleDialog,
  },
  data: () => ({
    search: '',
    filter: {},
    dialogFilter: false,
    categoryItems: [],
    loadingTable: false,
    
    dialogDelete: false,
    dialog: false,
    loading: false,
    headers: [
      {
        text: 'ID',
        align: 'start',
        value: 'id',
      },
      {
        text: 'ORIGEM',
        align: 'start',
        value: 'origin',
      },
      {
        text: 'ROOM-NIGHTS',
        align: 'center',
        value: 'diary',
      },
      {
        text: 'RECEITA DE HOSPEDAGEM',
        value: 'sumDiary',
        align: 'right',
      },
      {
        text: 'DIÁRIA MÉDIA',
        value: 'mediaDiary',
        align: 'right',
      },
      {
        text: '',
        value: 'actions',
        align: 'end',
      },
    ],
    companies: [],
    editedIndex: -1,
    editedItem: {
      description: '',
    },
    defaultItem: {
      description: '',
    },

    icons: {
      mdiPencilOutline,
      mdiDeleteOutline,
      mdiPlus,
      mdiClose,
      mdiOpenInNew,
      mdiTools,
      mdiFileExcelOutline,
      mdiFilter,
      mdiMagnify,
    },
  }),

  computed: {
    newTab() {
      let url_atual = window.location.href
      url_atual = url_atual.replace('#', '')
      return url_atual + '_blank'
    },
  },
  watch: {},

  created() {
    this.initialize()
  },

  methods: {
    ...mapActions('production_by_company', ['getAll', 'exportExcel']),
    initialize() {
      this.loadingTable = true
      this.getAll(this.filter)
        .then(response => {
          this.companies = response.data
        })
        .finally(() => {
          this.loadingTable = false
        })
    },
    showDetails(company) {
      this.$router.push({
        name: 'report/production_by_company_details',
        params: { id: company.id, company: company.origin },
      })
    },
    confirmSearch() {
      this.initialize()
    },
    confirmFilter() {
      this.initialize()
      this.dialogFilter = false
    },
    clearFilter() {
      this.filter = {}
    },
    sendExportExcel() {
      this.exportExcel(this.filter)
        .then(response => {
          var fileURL = window.URL.createObjectURL(new Blob([response.data]))
          var fileLink = document.createElement('a')

          fileLink.href = fileURL
          fileLink.setAttribute('download', 'export.xlsx')
          document.body.appendChild(fileLink)
          fileLink.click()
        })
        .catch(response => {
          console.log(response)
        })
        .finally(() => {})
    },

    descriptionRoom(room) {
      if (room) {
        return room.description
      } else {
        return ''
      }
    },
    originDescription(origin) {
      if (origin == 'reserve') {
        return 'RESERVA'
      } else if (origin == 'checkin') {
        return 'CHECKIN'
      }
    },
    // roomDescription(item) {
    //   console.log(item.room)
    //   let rooms_descrition = []
    //   if (item.room != null) {
    //     item.room.forEach(room => {
    //       rooms_descrition.push(room.description)
    //     })
    //     return rooms_descrition.join()
    //   }
    //   return ''
    // },
    qtdRoom(item) {
      if (item.room.length > 0) {
        return item.room.length
      } else if (item.qtd_room) {
        return item.qtd_room
      } else {
        return 0
      }
    },

    qtdCompany() {
      return this.companies.length
    },

    totalRoomNight() {
      return this.companies.reduce((acc, item) => {
        return acc + parseFloat(item.diary)
      }, 0)
    },
    diaryTotal() {
      return this.companies.reduce((acc, item) => {
        return acc + parseFloat(item.sumDiary)
      }, 0)
    },
    diaryMedia() {
      return this.companies.reduce((acc, item) => {
        return acc + parseFloat(item.mediaDiary)
      }, 0)
    },
    firstGuest(item) {
      if (item.guest.length > 0) {
        return item.guest[0].name
      } else if (item.guest_unknown.length > 0) {
        return item.guest_unknown[0].name
      }
      return ''
    },
    allGuests(item) {
      let guests = []
      item.guest.forEach(guest => {
        guests.push(guest.name)
      })

      item.guest_unknown.forEach(guest => {
        guests.push(guest.name)
      })

      return guests.join()
    },
    formatDate(date) {
      if (date) {
        const result = new Date(date)
        return result.toLocaleDateString('pt-BR', {
          timeZone: 'UTC',
        })
      }
    },
    formatDateTime(date) {
      if (date) {
        const result = new Date(date)
        return (
          result.toLocaleDateString('pt-BR', {
            timeZone: 'UTC',
          }) +
          ' ' +
          result.toLocaleTimeString('pt-BR')
        )
      }
    },
    formatMoney(value) {
      value = parseFloat(value)
      if (value != null) {
        return value.toLocaleString('pt-br', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })
      }
    },
    countReserves() {
      return this.companies.length
    },
  },
}
</script>
